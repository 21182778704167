import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to my Website 🚀</h1>
        <h3>Currently this is only a Playground for various tests 🎨</h3>
      </header>
    </div>
  );
}

export default App;
